<template>
  <div class="creditWrap">
    <el-row :gutter="12">
      <el-col :span="8">
        <div class="topItem">
          <div>
            <h1>80</h1>
            <p>总学分</p>
          </div>
          <img src="../assets/images/topItemBg1.png" alt="" />
        </div>
      </el-col>
      <el-col :span="8">
        <div class="topItem">
          <div>
            <h1>24</h1>
            <p>已用学分</p>
          </div>
          <img src="../assets/images/topItemBg2.png" alt="" />
        </div>
      </el-col>
      <el-col :span="8">
        <div class="topItem">
          <div>
            <h1>56</h1>
            <p>剩余学分</p>
          </div>
          <img src="../assets/images/topItemBg3.png" alt="" />
        </div>
      </el-col>
    </el-row>
    <div class="bottomWrap">
      <div class="bottomTitle">
        <div>
          <ul class="exchangeTab">
            <li
              v-for="(item, index) in exchangeTab"
              :key="index"
              :class="nowActive == item.id ? 'on' : ''"
              @click="changeTab(item)"
            >
              {{ item.name }}
            </li>
          </ul>
        </div>
        <el-button round class="exchangeBtn" @click="getExchengeLog"
          >兑换记录</el-button
        >
      </div>
      <div class="bottomContent">
        <el-row :gutter="20">
          <el-col :span="8" v-for="(item, index) in exchangeList" :key="index">
            <div class="bottomItem">
              <img
                class="exchangeStatus"
                src="../assets/images/exchangeAll.png"
                alt=""
                v-if="item.status == 1"
              />
              <img
                class="exchangeStatus"
                src="../assets/images/exchangeSome.png"
                alt=""
                v-if="item.status == 2"
              />
              <img class="itemImg" :src="item.img" alt="" />
              <div class="itemInfo">
                <p class="ellis1">{{ item.title }}</p>
                <p>
                  <span>{{ item.time }}</span>
                  <span>{{ item.credit }}学分</span>
                </p>
              </div>
            </div>
          </el-col>
        </el-row>
        <p class="moreBtn" @click="getMore">
          <i class="iconfont icon-more_btn mr5"></i>{{ btnMessage }}
        </p>
      </div>
    </div>
    <ExchangeLog ref="ExchangeLog"></ExchangeLog>
  </div>
</template>

<script>
import ExchangeLog from "./com/exchangeLog.vue";
export default {
  components: {
    ExchangeLog,
  },
  data() {
    return {
      page: 1,
      btnMessage: "加载更多",
      exchangeTab: [
        {
          id: 1,
          name: "全部",
        },
        {
          id: 2,
          name: "未兑换",
        },
        {
          id: 3,
          name: "已兑换",
        },
      ],
      nowActive: 1,
      exchangeList: [],
    };
  },
  mounted() {
    this.exchangeList = [
      {
        id: 1,
        img: "https://z3.ax1x.com/2021/11/11/IwNP6x.png",
        title: "21考研考前英语阅读写作21考研考前英语阅读写作",
        time: "2021-08-03",
        credit: "2",
        status: 1,
      },
      {
        id: 1,
        img: "https://z3.ax1x.com/2021/11/11/IwNP6x.png",
        title: "21考研考前英语阅读写作",
        time: "2021-08-03",
        credit: "2",
        status: 2,
      },
      {
        id: 1,
        img: "https://z3.ax1x.com/2021/11/11/IwNP6x.png",
        title: "21考研考前英语阅读写作",
        time: "2021-08-03",
        credit: "2",
        status: 3,
      },
      {
        id: 1,
        img: "https://z3.ax1x.com/2021/11/11/IwNP6x.png",
        title: "21考研考前英语阅读写作",
        time: "2021-08-03",
        credit: "2",
        status: 3,
      },
      {
        id: 1,
        img: "https://z3.ax1x.com/2021/11/11/IwNP6x.png",
        title: "21考研考前英语阅读写作",
        time: "2021-08-03",
        credit: "2",
        status: 3,
      },
    ];
  },
  methods: {
    changeTab(item) {
      this.nowActive = item.id;
      this.exchangeList = [];
    },
    getExchangeList() {},
    getMore() {
      if (this.btnMessage == "加载更多") {
        this.page++;
        this.getExchangeList();
      }
    },
    getExchengeLog() {
      this.$refs.ExchangeLog.openInit();
    },
  },
};
</script>

<style scoped lang="scss">
.creditWrap {
  .topItem {
    height: 100px;
    padding: 13px 25px;
    box-sizing: border-box;
    background: #fff;
    border-radius: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 14px;
    div {
      padding: 5px 0;
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      p {
        color: #86819f;
      }
    }
  }
  .bottomWrap {
    margin-top: 15px;
    background: #fff;
    border-radius: 8px;
    padding: 15px 20px 30px;
    box-sizing: border-box;
    .bottomTitle {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .exchangeTab {
        display: flex;
        li {
          margin-right: 40px;
          font-size: 16px;
          cursor: pointer;
          position: relative;
          &.on {
            color: var(--change-color);
            font-weight: bold;
          }
          &.on::after {
            clear: both;
            content: "";
            position: absolute;
            left: 50%;
            bottom: -9px;
            width: 24px;
            height: 3px;
            margin-left: -12px;
            background: var(--change-color);
            border-radius: 1.5px;
          }
        }
      }

      .exchangeBtn {
        padding: 10px 23px;
        background:#fff;border:1px solid var(--change-color);
        color: var(--change-color);
      }
    }
    .bottomContent {
      .bottomItem {
        height: 203px;
        background: #fff;
        box-shadow: 0 3px 15px rgba(0, 0, 0, 0.06);
        margin-top: 20px;
        display: flex;
        flex-direction: column;
        cursor: pointer;
        border-radius: 6px;
        position: relative;
        .exchangeStatus {
          position: absolute;
          left: 0;
          top: 0;
        }
        img.itemImg {
          width: 100%;
          height: 127px; 
          border-radius: 6px 6px 0 0;
        }
        .itemInfo {
          height: 76px;
          padding: 13px 20px;
          box-sizing: border-box;
          font-size: 16px;
          border-radius: 0 0 6px 6px;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          .ellis1 {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
          span {
            font-size: 14px;
            color: #999;
            margin-right: 15px;
          }
        }
      }
      .moreBtn {
        margin: 0 auto;
        margin-top: 30px;
        width: 100px;
        height: 30px;
        font-size: 14px;
        border-radius: 18px;
        border: 1px solid #e1e1e1;
        text-align: center;
        line-height: 30px;
        cursor: pointer;
        color: #666;
        &:hover{
        background:var(--change-color);
        border:1px solid var(--change-color);
        color:#fff;
      }
        i {
          font-size: 14px;
        }
      }
    }
  }
}
</style>
