<template>
	<div class="dialogWrap">
		<el-dialog title="兑换记录" :visible.sync="dialogVisible" width="700px">
			<el-timeline>
				<el-timeline-item v-for="(item, index) in logList" :key="index">
					<p class="fontSize16 mb10">
						{{ item.title }}&nbsp;&nbsp;{{ item.credit }}学分
					</p>
					<p style="color: #666">{{ item.time }}</p>
					<el-row :gutter="20">
						<el-col
							:span="12"
							v-for="(ite, ind) in item.exchangeList"
							:key="ind"
						>
							<div class="bottomItem">
								<img class="itemImg" :src="ite.img" alt="" />
								<div class="itemInfo">
									<p class="ellis1">{{ ite.title }}</p>
									<p>
										<span>{{ ite.time }}</span>
										<span>{{ ite.credit }}学分</span>
									</p>
								</div>
							</div>
						</el-col>
					</el-row>
				</el-timeline-item>
			</el-timeline>
			<p class="moreBtn" @click="getMore">
				<i class="iconfont icon-more_btn mr5"></i>{{ btnMessage }}
			</p>
		</el-dialog>
	</div>
</template>

<script>
export default {
	data() {
		return {
			dialogVisible: false,
			page: 1,
			btnMessage: '加载更多',
			logList: [
				{
					id: 1,
					title: '证书名称证书名称证书名称',
					credit: 2,
					time: '2021-08-13',
					exchangeList: [
						{
							id: 1,
							img: 'https://z3.ax1x.com/2021/11/11/IwNP6x.png',
							title: '21考研考前英语阅读写作21考研考前英语阅读写作',
							time: '2021-08-03',
							credit: '2',
							status: 1,
						},
						{
							id: 1,
							img: 'https://z3.ax1x.com/2021/11/11/IwNP6x.png',
							title: '21考研考前英语阅读写作',
							time: '2021-08-03',
							credit: '2',
							status: 2,
						},
					],
				},
				{
					id: 1,
					title: '证书名称证书名称证书名称',
					credit: 2,
					time: '2021-08-13',
				},
			],
		};
	},
	mounted() {},
	methods: {
		openInit() {
			this.dialogVisible = true;
			this.getLogList();
		},
		getLogList() {
			this.logList = [
				{
					id: 1,
					title: '证书名称证书名称证书名称',
					credit: 2,
					time: '2021-08-13',
					exchangeList: [
						{
							id: 1,
							img: 'https://z3.ax1x.com/2021/11/11/IwNP6x.png',
							title: '21考研考前英语阅读写作21考研考前英语阅读写作',
							time: '2021-08-03',
							credit: '2',
							status: 1,
						},
						{
							id: 1,
							img: 'https://z3.ax1x.com/2021/11/11/IwNP6x.png',
							title: '21考研考前英语阅读写作',
							time: '2021-08-03',
							credit: '2',
							status: 2,
						},
					],
				},
				{
					id: 1,
					title: '证书名称证书名称证书名称',
					credit: 2,
					time: '2021-08-13',
				},
			];
		},
		getMore() {
			if (this.btnMessage == '加载更多') {
				this.page++;
				this.getLogList();
			}
		},
	},
};
</script>

<style scoped lang="scss">
.dialogWrap {
	/deep/.el-dialog {
		border-radius: 16px;
		.el-dialog__header {
			border-bottom: 1px solid #eeeeee;
			padding: 20px;
			.el-dialog__title {
				margin: 20px 0 20px 20px;
				font-size: 20px;
				font-weight: bold;
			}
			.el-dialog__headerbtn .el-dialog__close {
				font-size: 18px;
			}
		}
		.el-dialog__body {
			padding: 30px 40px 30px 30px;
		}
	}
	/deep/.el-timeline-item__wrapper {
		padding-left: 22px;
	}
	/deep/.el-timeline-item__node--normal {
		width: 9px;
		height: 9px;
		background: var(--default-color);
		left: 0;
		top: 3px;
	}
	/deep/.el-timeline-item__tail {
		border-left-width: 1px;
		margin-top: 2px;
	}
	.bottomItem {
		height: 246px;
		background: #fff;
		box-shadow: 0 3px 15px rgba(0, 0, 0, 0.06);
		margin-top: 10px;
		display: flex;
		flex-direction: column;
		cursor: pointer;
		border-radius: 6px;
		img.itemImg {
			width: 100%;
			height: 162px;
			border-radius: 6px 6px 0 0;
		}
		.itemInfo {
			height: 84px;
			padding: 13px 20px;
			box-sizing: border-box;
			font-size: 16px;
			border-radius: 0 0 6px 6px;
			display: flex;
			flex-direction: column;
			justify-content: space-between;
			.ellis1 {
				white-space: nowrap;
				overflow: hidden;
				text-overflow: ellipsis;
			}
			span {
				font-size: 14px;
				color: #999;
				margin-right: 15px;
			}
		}
	}
	.moreBtn {
		margin: 0 auto;
		margin-top: 30px;
		width: 100px;
		height: 30px;
		font-size: 14px;
		border-radius: 18px;
		border: 1px solid #e1e1e1;
		text-align: center;
		line-height: 30px;
		cursor: pointer;
		color: #666;
		&:hover {
			background: var(--change-color);
			border: 1px solid var(--change-color);
			color: #fff;
		}
		i {
			font-size: 14px;
		}
	}
}
</style>
